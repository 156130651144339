.paginator {
	border-top: 1px dotted #3a4972;
	padding: 30px 0;

	&__pages {
		margin: 0;
		padding: 0 0 0 13px;
		list-style: none;
		font-size: 0;
	}

	&__page {
		display: inline-block;
		vertical-align: top;
		font-size: 16px;
		margin-left: 5px;
		margin-bottom: 10px;

		a {
			display: block;
			color: @blue;
			text-decoration: none;
			border-radius: 4px;
			padding: 6px 12px 7px;
			transition: all .15s;

			&:hover {
				color: @darkblue;
			}
		}
	}

	&__page_current {
		a {
			background: @blue;
			color: white;
			padding: 6px 15px 7px;
			pointer-events: none;
		}
	}

	&__page_prevnext {
		a {
			border: 1px solid #c0c7e0;

			&:hover {
				border-color: @darkblue;
			}
		}
	}
}