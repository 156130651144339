.interview {
	display: table;
	width: 100%;
	height: 430px;
	margin: 20px 0;
	text-align: left;
	font-size: 17px;
	padding-bottom: 52px;

	&__cell {
		display: table-cell;
		vertical-align: top;
		width: 50%;

		&:first-child {
			padding-right: 10px;
		}

		&:last-child {
			padding-left: 10px;
		}
	}

	&__img {
		width: 100%;
		height: 430px;
		border-radius: 6px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	&__body {
		padding: 20px 20px 20px 64px;
		height: 100%;
		background: white;
		border-radius: 6px;
	}
}