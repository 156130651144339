.modal {
	display: none;
	width: 695px;
	max-width: 100%;
	padding: 50px 80px 60px;
	color: black;
	background: white;

	&__title {
		display: block;
		font-family: @lora;
		font-size: 26px;
		font-weight: bold;
		line-height: 2.154;
		text-align: center;
		color: #3a4972;
	}

	&__close {
		position: absolute;
		top: 17px;
		right: 17px;
		width: 16px;
		height: 16px;
		padding: 0;
		background: none;
		border: none;

		svg {
			fill: #ced1df;
			max-width: 100%;
			max-height: 100%;
			transition: all .15s;
		}

		&:focus {
			outline: none;
		}

		&:hover {
			svg {
				fill: black;
			}
		}
	}
}