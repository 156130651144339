.news {
	margin: 40px 0 4px;
	padding: 0;
	list-style: none;
	font-size: 0;

	&__item {
		position: relative;
		z-index: 133;
		display: inline-block;
		vertical-align: top;
		width: 31.7%;
		height: 480px;
		margin-left: 2.45%;
		margin-bottom: 30px;
		border-radius: 4px;
		background: white;
		box-shadow: 0 10px 10px -5px rgba(0,0,0,.1);
		text-align: left;
		perspective: 500px;
		overflow: hidden;

		&:first-child,
		&:not(.news__item_index):nth-child(3n+4) {
			margin-left: 0;
		}

		&:hover {
			.news__item-img {
				transform: scale(1.03) translateX(-1%) translate3d(0,0,0);
			}
		}
	}

	&__item_index {
		width: 49.2%;
		height: 200px;
		box-shadow: 0 10px 10px -5px rgba(0,0,0,.01);
		margin-left: 1.6%;
		margin-bottom: 31px;

		&:nth-child(odd) {
			margin-left: 0;
		}

		.news__item-img {
			display: inline-block;
			vertical-align: top;
			width: 49.1%;
		}

		.news__item-wrap {
			display: inline-block;
			vertical-align: top;
			width: 50.9%;
			padding: 14px 15px 20px 28px;
		}

		.news__item-img {
			height: inherit;
		}

		.news__item-date {
			margin-bottom: 15px;
		}
	}

	&__item-img {
		width: 100%;
		height: 260px;
		background-repeat: no-repeat;
		background-size: cover;
		transition: all .3s;
	}

	&__item-wrap {
		padding: 43px 40px 20px 40px;
		font-size: 20px;
	}

	&__item-title {
		font-family: @lora;
		font-size: 20px;
		font-weight: 600;
		color: @blue;
		text-decoration: none;
	}

	&__item-date {
		margin-bottom: 15px;
	}
}

.news_page {
	margin: 54px 0 4px;
}

.newsitem {

	&__content {
		display: table-cell;
		vertical-align: top;
		width: 80%;
		padding-right: 55px;
	}

	&__gallery {
		display: table-cell;
		vertical-align: top;
		width: 20%;
	}
}