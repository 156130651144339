.contacts {
	text-align: center;

	&__buttons {
		button.btn, a.btn {
			display: inline-block;
			vertical-align: top;
			width: 184px;
			margin: 0;
			margin-left: 10px;
			line-height: 42px;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	&__info {
		display: table;
		margin: 70px 0 30px;
		width: 100%;
		padding: 16px 27px;
		text-align: left;
		border: 3px solid #ededf0;
		border-radius: 8px;

		p {
			margin: 0;
		}
	}

	&__tel {
		font-size: 36px;
		color: rgb(39, 39, 39);
		line-height: 0.694;
		text-decoration: none;
		line-height: 17px;
	}

	&__cell {
		display: table-cell;
		vertical-align: middle;
		width: 40%;
		padding: 20px 0 20px 40px;
	}

	&__cell_place {
		background: url('../img/icons/place_big.png') no-repeat left center;
	}

	&__cell_tel {
		background: url('../img/icons/tel_big.png') no-repeat left center;
	}

	&__cell_soc {
		width: 20%;
		text-align: right;
	}

	&__caption {
		display: block;
		margin-bottom: 20px;
		font-size: 16px;
		font-family: @lora;
		color: rgb(54, 94, 149);
		font-weight: bold;
		text-transform: uppercase;
		line-height: 1.625;
	}

	&__map {
		margin: 30px 0 100px;
		width: 100%;
		height: 400px;
		border: 4px solid white;
	}

	#map {
		height: 100%;
	}

	&__units {
		margin: 30px 0 60px;
		padding: 0;
		list-style: none;
	}
}

.unit {
	text-align: center;
	background: white;
	margin-bottom: 1px;
	padding: 20px 0;
	font-size: 17px;

	&__wrap {
		display: table;
		width: 1000px;
		margin: 0 auto;
	}

	&__cell {
		display: table-cell;
		vertical-align: middle;
		width: 33%;
		text-align: left;
	}

	&__tel {
		display: inline-block;
		font-size: 20px;
		color: black;
		padding-left: 21px;
		background: url('../img/icons/tel_g.png') no-repeat left center;
		text-decoration: none;
	}

	&__mail {
		color: #365e95;
		padding-left: 30px;
		background: url('../img/icons/mail.png') no-repeat left center;
		text-decoration: none;
	}
}