.menu {
	margin: 0 auto;
	padding: 0;
	list-style: none;
	text-align: center;
	font-size: 15px;

	&__item {
		position: relative;
		z-index: 1;
		display: inline-block;
		vertical-align: top;
		font-family: @lora;
		padding: 0 20px;

		&::before {
			position: absolute;
			left: -3px;
			top: 8px;
			content: '';
			width: 4px;
			height: 4px;
			background: white;
			border-radius: 50%;
		}

		&:last-child::after {
			position: absolute;
			right: -3px;
			top: 8px;
			content: '';
			width: 4px;
			height: 4px;
			background: white;
			border-radius: 50%;
		}

		& > a {
			color: white;
			text-transform: uppercase;
			text-decoration: none;
			transition: all .15s;

			&:hover {
				color: @yellow;
			}
		}
	}

	&__item_drop:hover {
		.drop {
			top: 100%;
			opacity: 1;
			visibility: visible;
		}
	}
}

.drop {
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	opacity: 0;
	visibility: hidden;
	background: #474a51;
	// width: 100%;
	padding: 40px 0 20px;
	text-align: left;
	// box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	transition: all .3s;

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
		border-top: 1px solid #45484f;
	}

	&__item {
		padding: 0 24px;
		white-space: nowrap;

		& > a {
  			font-size: 15px;
			font-weight: bold;
			line-height: 2.667;
			color: white;
			text-decoration: none;
			transition: all .15s;

			&:hover {
				color: @yellow;
			}
		}
	}
}