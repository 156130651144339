.sidebar {
	color: white;
	border-radius: 8px;
	background: #2b334d;

	&__block {
		padding: 20px;
		border-bottom: 1px dotted rgb(197, 204, 227);
	}

	&__caption {
		display: block;
		padding-left: 12px;
		font-size: 20px;
		font-family: @lora;
		color: rgb(197, 204, 227);
		font-weight: bold;
		line-height: 1.3;
		margin-bottom: 15px;
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
		font-family: @lora;
		font-size: 13px;
	}

	&__list-item {
		position: relative;
		padding: 17px 0;
		padding-left: 12px;

		&:last-child {
			padding-bottom: 0;
		}

		&::after {
			position: absolute;
			left: 0;
			top: 0;
			width: 65px;
			height: 0;
			content: '';
			border-bottom: 1px dotted rgb(197, 204, 227);
		}

		a {
			color: white;
			text-decoration: none;
			text-transform: uppercase;
			transition: all .15s;

			&:hover {
				opacity: .9;
			}
		}
	}

	&__list-item_active {
		a {
			color: @yellow;
			pointer-events: none;
		}
	}
}