.social {
	margin: 0 10px 0 0;
	padding: 0;
	list-style: none;
	font-size: 0;

	&__item {
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: white;
		margin-right: 10px;
		transition: all .15s;

		svg {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			max-width: 70%;
			max-height: 70%;
			fill: #474a51;
			transition: all .15s;
		}

		&:hover {
			background: @yellow;

			svg {
				transform: translate(-50%,-50%) rotate(15deg);
			}
		}

		a {
			position: absolute;
			left: 0; top: 0; right: 0; bottom: 0;
		}

		i {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			transition: all .15s;
		}
	}
}

.social_contacts {
	margin-top: 20px;

	.social__item {
		background: #8188a1;

		svg {
			fill: white;
		}
	}
}

.stitches-social-ico(@x: 0, @y: 0, @width: 0, @height: 0) {
    background-position: @x @y;
    width: @width;
    height: @height;
}

.social-ico {
    background-image: url('../img/icons/social.png');    background-repeat: no-repeat;
    display: block;

    &.social-ico-fb {
        .stitches-social-ico(-5px, -5px, 12px, 24px);
    }

    &.social-ico-ok {
        .stitches-social-ico(-27px, -5px, 16px, 25px);
    }

    &.social-ico-vk {
        .stitches-social-ico(-53px, -5px, 26px, 15px);
    }
}

.social-ico_w {
    background-image: url('../img/icons/social_w.png');    background-repeat: no-repeat;
}

.social-ico_w {
    background-image: url('../img/icons/social_w.png');    background-repeat: no-repeat;
    display: block;

    &.social-ico-fb_w {
        .stitches-social-ico(-5px, -5px, 10px, 20px);
    }

    &.social-ico-ok_w {
        .stitches-social-ico(-25px, -5px, 14px, 22px);
    }

    &.social-ico-vk_w {
        .stitches-social-ico(-49px, -5px, 23px, 13px);
    }
}
