.catalog {
	font-size: 0;
	margin: 0 0 40px;
	padding: 0;
	list-style: none;

	&__item {
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 32.2%;
		height: 300px;
		margin-left: 1.7%;
		margin-bottom: 20px;
		font-size: 26px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		border-radius: 4px;
		overflow: hidden;

		&:first-child {
			margin-left: 0;
		}

		a {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			color: white;

			&:hover {
				color: @yellow;
			}
		}

		&:nth-child(3n+4) {
			margin-left: 0;
		}
	}

	&__item-title {
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 27px 30px 30px;
		line-height: 1.2;
		height: 115px;
		width: 100%;
		text-align: center;
		font-size: 26px;
		font-family: @lora;
		background: url('../img/catalog/bottom.png') no-repeat center bottom;
	}
}

.desc {
	position: relative;
	z-index: 3;
	min-height: 315px;
	padding: 37px 0;
	margin-bottom: 40px;

	&__text {
		margin-left: 70px;
		padding-left: 320px;
		color: white;
		background: #474e65;
		min-height: 247px;
		padding-top: 40px;
		font-size: 17px;
		line-height: 1.412;
		font-weight: 200;
		padding-right: 60px;
		border-radius: 12px;

		p {
			margin: 0;
		}
	}

	&__img {
		position: absolute;
		left: 0;
		top: 0;
		width: 315px;
		height: 315px;
		border-radius: 50%;
		background: rgba(255,255,255,.7);
	}

	&__img-wrap {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		width: 295px;
		height: 295px;
		border-radius: 50%;
		overflow: hidden;

		img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.item {
	display: inline-block;
	vertical-align: top;
	width: 24%;
	height: 430px;
	font-size: 15px;
	background: white;
	max-width: 290px;
	border-radius: 8px;
	text-align: center;
	padding: 15px;
	box-shadow: 0 8px 8px -5px rgba(0,0,0, 0.1);
	margin-left: 2.1%;
	margin-bottom: 23px;

	&:nth-child(1) {
		margin-left: 0;
	}

	&:nth-child(4n+5) {
		margin-left: 0;
		background: white;
	}

	&__title {
		display: block;
		padding: 0 10%;
		font-size: 18px;
		font-weight: bold;
		color: #365e95;
		margin-bottom: 25px;
	}

	&__props {
		text-align: left;
	}

	&__img {
		position: relative;
		width: 100%;
		height: 187px;
		overflow: hidden;
		margin-bottom: 20px;

		img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
		}
	}
}

.props {
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 15px;
	padding: 0 15px;

	&__item {
		position: relative;
		z-index: 1;
		margin-bottom: 10px;
		
		&::before {
			position: absolute;
			z-index: -1;
			left: 0;
			bottom: 5px;
			width: 100%;
			height: 0;
			content: '';
			border-bottom: 1px dotted black;
		}
	}

	&__name {
		padding-right: 8px;
		background: white;
		color: rgba(0,0,0,.7);
	}

	&__val {
		float: right;
		font-weight: bold;
		background: white;
		padding-left: 8px;
	}
}