.category {
	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
		font-size: 0;
	}

	&__item {
		display: inline-block;
		vertical-align: top;
		margin-right: 6px;
		font-size: 20px;

		&:first-child {
			margin-right: 8px;
		}

		a {
			position: relative;
			display: block;
			color: black;
			text-decoration: none;
			border-radius: 4px;
			padding: 9px 16px;

			&::before {
				position: absolute;
				left: 50%;
				bottom: 4px;
				transform: translateX(-50%);
				width: 70%;
				height: 0;
				content: '';
				border-bottom: 1px dotted black;
				transition: all .15s;
			}

			&:hover {
				color: @darkblue;

				&::before {
					border-color: transparent;
				}
			}
		}
	}

	&__item_current a {
		color: white;
		background: @blue;
		pointer-events: none;

		&::before {
			display: none;
		}
	}

}