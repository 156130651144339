﻿@import url("normalize.less");
@import url("fonts.less");
@import url("mixins.less");
@import url("icons.less");

@import url('../components/header/style.less');
@import url('../components/search/style.less');
@import url('../components/logo/style.less');
@import url('../components/navigation/style.less');
@import url('../components/slider/style.less');
@import url('../components/about/style.less');
@import url('../components/f-block/style.less');
@import url('../components/catalog/style.less');
@import url('../components/news/style.less');
@import url('../components/interview/style.less');
@import url('../components/footer/style.less');
@import url('../components/social/style.less');
@import url('../components/category/style.less');
@import url('../components/paginator/style.less');
@import url('../components/gallery/style.less');
@import url('../components/sidebar/style.less');
@import url('../components/vacancy/style.less');
@import url('../components/contacts/style.less');
@import url('../components/modals/style.less');

/* variables */	
@base: 15px;
@font: "MyriadPro";
@lora: 'Lora', sans-serif;
@yellow: #ffef69;
@darkblue: #3a4972;
@blue: #365e95;


/* * * * * * * * * * *
* 					 *
* Global             *
* Styles             *	
*                    *
* * * * * * * * * * */

html {
	font-size: @base;
}

html,
body {
	width: 100%;
	height: 100%;
}
	
body {
	font-family: @font, sans-serif;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-moz-font-feature-settings: 'liga', 'kern';

	.flex(column);
}

.page {
	font-family: 'MyriadPro', sans-serif;
	background: #474a51;
	color: white;
	min-width: 320px;

	* {
		box-sizing: border-box;
	}
}

.container {
	position: relative;
	width: 100%;
	max-width: 1240px;

	margin: 0 auto;
	padding: 0;
	box-sizing: border-box;
}

.wrapper {
	flex: 1 0 auto;
	background: #f9f9f9;
	color: black;
	overflow: hidden;

	.btn {
		border-bottom-color: @darkblue;
	}
}

.form {
	margin: 5px 0;

	&__line {
		display: block;
		padding: 8px 0;
		cursor: pointer;

		input {
			margin-right: 20px;
		}

		span {
			font-size: 17px;
		}
	}

	&__line.form__line_ctr {
		text-align: center;
	}

	.btn {
		margin-top: 33px;
	}

	&__agree {
		width: 70%;
		margin: 20px auto 0;
	}
}

.interview__quest.form .form__line {
	text-align: left;
}

.radio {
	input {
		display: none;
	}

	input:checked ~ .radio__box {
		&::before {
			transform: translate(-50%,-50%) scale(1);
		}
	}

	&__box {
		position: relative;
		display: inline-block;
		margin-right: 15px;
		padding: 0;
		width: 14px;
		height: 14px;
		border-radius: 50%;
		border-width: 1px;
		border-color: rgb(182, 182, 182);
		border-style: solid;
		background-color: rgb(255, 255, 255);
		box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.3);

		&::before {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%) scale(0);
			width: 60%;
			height: 60%;
			content: '';
			border-radius: 50%;
			background: @blue;
			transition: all .3s;
		}
	}
}

.agree {
	position: relative;
	display: block;
	width: 100%;
	cursor: pointer;

	&__inp {
		display: none;

		&:checked ~ .agree__box {
			&::before {
				display: block;
			}
		}
	}

	&__box {
		position: absolute;
		left: 0;
		top: 2px;
		border-radius: 2px;
		background-color: rgb(255, 255, 255);
		box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
		width: 16px;
		height: 16px;
		display: inline-block;
		vertical-align: top;

		&::before {
			display: none;
			position: absolute;
			left: 5px;
			top: 2px;
			content: '';
			width: 5px;
			height: 8px;
			border-bottom: 1px solid #3a4972;
			border-right: 1px solid #3a4972;
			transform: rotate(40deg);
		}
	}

	p {
		display: block;
		margin: 0;
		padding-left: 40px;
		color: #767676;
	}
}

.title {
	margin: 0;
	font-family: @lora;
	font-size: 34px;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1.647;
	color: @darkblue;
	letter-spacing: 1px;
	line-height: 1.2;

	span {
		position: relative;
		padding: 0 20px;
		
		&::before, &::after {
			position: absolute;
			top: 18px;
			content: '';
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: @darkblue;
		}

		&::before {
			left: 0;
		}

		&::after {
			right: 0;
		}
	}
}

.title_page {
	padding: 0 0 20px;
	font-size: 42px;
	text-transform: none;
	text-align: center;
	color: @blue;
}

.section {
	padding: 0 0 67px;
	text-align: center;

	&__title {
		margin: 9px 0 25px;
		padding: 0 0 10px;
		border-bottom: 1px dotted;
		text-align: center;
	}

	&__content {
		text-align: left;
		margin: 6px 0;
		padding: 16px 0 11px;
		border-top: 1px dotted @darkblue;
		// border-bottom: 1px dotted @darkblue;
	}

	&__content_page {
		// border-bottom: none;
		padding: 40px 35px;
		font-weight: 200;
  		line-height: 1.471;
		font-size: 17px;

		& > p {
			margin: 0 0 15px;
		}

		& > img {
			display: block;
			margin: 5px auto;
			max-width: 100%;
		}

		strong {
			font-family: @lora;
			font-size: 17px;
			font-weight: bold;
			display: block;
			margin-bottom: 8px;
		}
	}

	&__content_vac {
		padding: 50px 0;
	}

	&__sidebar {
		display: inline-block;
		vertical-align: top;
		width: 24%;
		max-width: 276px;
		text-align: left;
	}

	&__main {
		display: inline-block;
		vertical-align: top;
		width: 74%;
		max-width: 906px;
		margin-bottom: 100px;

		// .section__content_page {
		// 	padding: 0;
		// }
	}
}

.section_fhf {
	position: relative;
	z-index: 1;
	margin: 90px 0 198px;
	padding: 50px 0;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		content: '';
		width: 100%;
		height: 0;
		border-top: 9px solid rgba(38,64,108,.05);
	}

	&::after {
		position: absolute;
		bottom: 0;
		left: 0;
		content: '';
		width: 62%;
		height: 0;
		border-bottom: 9px solid rgba(38,64,108,.05);
	}
}

.section.section_wside {
	text-align: justify;

	&::after {
		display: inline-block;
		width: 100%;
		height: 0;
		content: '';
	}
}

.ulist {
	margin: 25px 0;
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding-left: 50px;
		margin-bottom: 20px;

		&::before {
			position: absolute;
			left: 15px;
			top: 11px;
			width: 18px;
			height: 2px;
			background: @darkblue;
			content: '';
		}
	}
}

.ulist_order {
	padding-left: 15px;
	counter-reset: item;

	li {
		padding: 0;

		&::before {
			position: static;
			display: inline-block;
			counter-increment: item;
			content: counter(item)')';
			width: auto;
			height: auto;
			background: none;
			color: @blue;
			margin-right: 20px;
		}
	}
}

.section_page {
	padding: 35px 0;
	text-align: left;
}

.section.about {
	padding: 60px 0;
}

.quote {
	position: relative;
	z-index: 1;
	padding: 40px 110px;
	background: white;
  	border-radius: 8px;
  	font-family: @lora;
  	font-size: 20px;
  	font-style: italic;

  	&::before {
  		position: absolute;
  		z-index: -1;
  		left: 20px;
  		top: -15px;
  		content: '«';
  		font-size: 120px;
  		color: #f9f9f9;
  		line-height: 1;
  	}

  	&::after {
  		position: absolute;
  		z-index: -1;
  		right: 20px;
  		bottom: 10px;
  		content: '»';
  		font-size: 120px;
  		color: #f9f9f9;
  		line-height: 1;
  	}
	
	p {
		margin: 0 0 15px;
	}
}

.date {
	display: block;
	color: #8188a1;
	font-size: 17px;
	padding-left: 25px;
	background: url('../img/icons/cal.png') no-repeat left center;
}

.cell {
	display: table-cell;
}

.cell_lft {
	text-align: left;
}

.cell_cntr {
	text-align: center;
}

.cell_rght {
	text-align: right;
}

.group {
	h1 {
		margin: 20px 0 15px;
		line-height: 1.15;
		font-family: @lora;
		font-size: 42px;
		font-weight: 600;
		text-align: center;

		strong {
			display: block;
			text-transform: uppercase;
		}
	}
}

.medals {
	position: relative;
	top: 28px;
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;

	&__item {
		display: inline-block;
		margin: 0 -7px 0 -6px;
	}
}

.breadcrumbs {
	padding: 18px 0 17px;
	background: white;

	&__tree {
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: center;
	}

	&__item {
		display: inline-block;
		vertical-align: top;
		font-size: 12px;
		line-height: 1.4;
		letter-spacing: .5px;

		&::before {
			position: relative;
			top: 5px;
			display: inline-block;
			margin: 0 10px;
			vertical-align: top;
			width: 3px;
			height: 3px;
			content: '';
			border-top: 1px solid #8188a1;
			border-right: 1px solid #8188a1;
			transform: rotate(45deg);
		}

		&:first-child {
			a {
				border: none;
			}

			&::before {
				display: none;
			}
		}

		&:last-child {
			a {
				pointer-events: none;
				border: none;
			}
		}

		a {
			color: #8188a1;
			text-transform: uppercase;
			text-decoration: none;
			border-bottom: 1px dotted;
			transition: all .15s;

			&:hover {
				border-color: transparent;
			}
		}
	}
}

.form {
	&__line {
		display: block;
		font-size: 0;
		text-align: justify;

		&::after {
			display: inline-block;
			width: 100%;
			height: 0;
			content: '';
		}
	}

	&__lbl {
		display: inline-block;
		width: 48.5%;
		font-size: 15px;
	}

	&__field {
		width: 100%;
		height: 42px;
		padding: 0 20px;
		border-style: solid;
		border-width: 1px;
		border-color: rgb(239, 241, 247);
		border-radius: 4px;
		box-shadow: inset 0px 0px 5px 0px rgba(41, 56, 98, 0.12);
		background: white;
		font-size: 15px;

		&:focus {
			outline: none;
		}
	}

	&__field_textarea {
		height: 210px;
		padding: 15px 20px;
		resize: none;
	}
}

/* * * * * * * * * * *
 * 					 *
 * Pages             *
 * Styles            *
 *                   *
 * * * * * * * * * * */

.fhf {
	display: table;
	width: 564px;
	max-width: 100%;
	margin: 10px auto 20px;

	&::before {
		position: absolute;
		z-index: -1;
		left: calc(~'50% - 710px');
		top: -340px;
		width: 1386px;
		height: 1407px;
		content: '';
		background: url('../img/404bg.png') no-repeat center;
		background-size: contain;
	}
	
	&__cell {
		display: table-cell;
		vertical-align: top;
		text-align: left;
	}

	&__title {
		padding-right: 30px;
		font-family: @lora;
		font-size: 102px;
		font-weight: bold;
		color: rgb(54, 94, 149);
		line-height: 1.2;
		margin: 0;
	}

	&__caption {
		margin: 0 0 15px;
		font-family: @lora;
		font-size: 26px;
		color: rgb(54, 94, 149);
		font-weight: bold;
		text-transform: uppercase;
		line-height: 1.923;
	}

	&__text {
		margin: 0 0 30px;
		font-size: 17px;
		font-weight: 200;
	}
}


/* * * * * * * * * * *
* 					 *
* Block              *
* Styles             *
*                    *
* * * * * * * * * * */

.btn {
	display: inline-block;
	text-decoration: none;
	border-radius: 4px;
	background-color: #8188a1;
	background-image: -moz-linear-gradient(-90deg, rgb(255,255,255) 0%, rgba(255,255,255,0) 65%, rgb(255,255,255) 65%);
	background-image: -webkit-linear-gradient(-90deg, rgb(255,255,255) 0%, rgba(255,255,255,0) 65%, rgb(255,255,255) 65%);
	background-image: -ms-linear-gradient(-90deg, rgb(255,255,255) 0%, rgba(255,255,255,0) 65%, rgb(255,255,255) 65%);
	background-image: linear-gradient(0deg, rgba(255,255,255,.1) 0%, rgba(255,255,255,0) 65%, rgba(255,255,255,.2) 100%);
	box-shadow: inset 0px -1px 0px 0px rgba(213, 214, 210, 0.004);
	min-width: 177px;
	height: 42px;
	line-height: 40px;
	border: none;
	border-bottom: 1px solid #d5d6d2;
	color: white;
	text-transform: uppercase;
	font-size: 14px;
	font-family: @lora;
	font-weight: bold;
	transition: all .15s;
	max-width: 100%;
	padding: 0 20px;

	&:hover {
		color: @yellow;
	}

	&:active {
		border-color: transparent;
		transform: scale(.99);
	}

	&:focus {
		outline: none;
	}
}

.btn.btn_all {
	color: @blue;
	border: 1px solid #c0c7e0;
	background: transparent;
	box-shadow: none;
	text-transform: none;
	font-size: 16px;
	font-family: 'MyriadPro', sans-serif;
	padding: 0 10px;
	height: 32px;
	width: auto;
	font-weight: normal;
	margin-top: 25px;

	&:hover {
		background: @blue;
		color: white;
	}
}

.btn.btn_download {
	display: block;
	margin: 40px auto;
	border: 1px solid #c0c7e0;
	background: transparent;
	box-shadow: none;
	font-size: 14px;
	color:#8188a1;
	text-decoration: underline;
	width: 196px;

	&:hover {
		text-decoration: none;
	}
}

.flickity-prev-next-button {
	transition: all .15s;
}

.flickity-prev-next-button:hover {
	background: @yellow;
}

.mobile {
	display: none;
	position: relative;
	z-index: 1;
	padding: 10px 0;
	background: #474a51;

	&__menu {
		&.open {
			.mobile__menu-btn {
				background: url('../img/icons/close.svg') no-repeat center center;
				background-size: contain;
			}

			.mobile__menu-drop {
				top: 100%;
				opacity: 1;
				visibility: visible;
				box-shadow: 0 25px 50px 1px rgba(0,0,0,.7);	
			}
		}
	}

	&__menu-btn {
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%) translate3d(0,0,0);
		border: none;
		width: 26px;
		height: 19px;
		background: url('../img/icons/menu.png') no-repeat center;
		background-size: contain;

		&:focus {
			outline: none;
		}
	}

	&__search-btn {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%) translate3d(0,0,0);
		border: none;
		width: 22px;
		height: 22px;
		background: url('../img/icons/search_w.png') no-repeat center;
		background-size: contain;

		&:focus {
			outline: none;
		}
	}

	&__menu-drop {
		position: absolute;
		z-index: -1;
		left: 0;
		top: 0;
		background: #474a51;
		padding: 20px;
		width: 100%;
		opacity: 0;
		visibility: hidden;
		transition: all .3s;
	}

	&__tel {
		display: inline-block;
		margin-top: 10px;
		color: white;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 24px;
	}

	&__search {
		&.open {
			.mobile__search-field {
				top: 100%;
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__search-field {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		padding: 10px 0;
		background: #474a51;
		opacity: 0;
		visibility: hidden;
		transition: all .3s;

		input {
			width: 80%;
			height: 32px;
			border-radius: 8px;
			border: none;
			padding: 0 10px;
			color: black;

			&::placeholder {
				font-style: italic;
			}
		}
	}
}


/* * * * * * * * * * *
* 		   			 *
* Plugin             *
* Styles             *
*                    *
* * * * * * * * * * */

@import url('flickity.css');
@import url('nice-select.css');

.nice-select {
	float: none;
	// width: 100%;
	height: 42px;
	padding: 0 20px;
	border-style: solid;
	border-width: 1px;
	border-color: rgb(239, 241, 247);
	border-radius: 4px;
	box-shadow: inset 0px 0px 5px 0px rgba(41, 56, 98, 0.12);
	background: white;
	font-size: 15px;

	.list {
		width: 100%;
	}

	.option.disabled {
		display: none;
	}

	.current {
		color: rgba(0,0,0,.6);
	}
}

/* * * * * * * * * * *
* 					 *
* Animation          *
* Keyframes          *
*                    *
* * * * * * * * * * */



/* * * * * * * * * * *
 * 					 *
 * Modals            *
 * Styles            *
 *                   *
 * * * * * * * * * * */



/* * * * * * * * * * *
* 					 *
* Media              *
* Queries            *
*                    *
* * * * * * * * * * */
@import url('1024.less');
@import url('600.less');