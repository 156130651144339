.header {
	padding-top: 40px;

	&__main {
		display: table;
		width: 100%;
		padding-bottom: 20px;
	}

	&__navigation {
		padding: 20px 0;
		border-top: 1px solid #aaadaf;
	}

	&__cell {
		vertical-align: bottom;
		width: 40%;
	}

	&__cell_logo {
		width: 20%;
		text-align: center;
	}

	&__cell_lft {
		text-align: left;
		padding-bottom: 38px;
	}

	&__cell_rght {
		text-align: right;
		padding-bottom: 24px;
	}

	&__tel {
		position: relative;
		top: 2px;
		padding-left: 45px;
		font-size: 26px;
		color: rgb(255, 255, 255);
		line-height: 0.846;
		text-decoration: none;
		margin-right: 40px;
		transition: all .15s;

		&::before {
			position: absolute;
			left: 0;
			top: 0;
			content: '';
			width: 30px;
			height: 30px;
			border: 1px solid white;
			border-radius: 50%;
		}

		&::after {
			position: absolute;
			left: 7px;
			top: 7px;
			content: '';
			width: 18px;
			height: 18px;
			background: url('../img/icons/tel.png') no-repeat;
		}

		&:hover {
			color: @yellow;
		}
	}
}
