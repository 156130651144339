.f-block {
	position: relative;
	display: table-cell;
	vertical-align: top;
	width: 25%;
	height: 281px;
	font-size: 17px;
	border: 3px solid #a9b0c8;
	border-left: none;
	background: white;
	text-align: center;
	color: @darkblue;
	padding: 20px;

	&:first-child {
		border-left: 3px solid #a9b0c8;
		border-radius: 8px 0 0 8px;
	}

	&:last-child {
		border-radius: 0 8px 8px 0;
	}

	&__val {
		display: block;
		font-family: @lora;
		font-size: 30px;
		font-weight: bold;
		line-height: 0.543;
		margin-bottom: 28px;

		strong {
			position: relative;
			top: 5px;
			font-size: 46px;
		}
	}

	&__img {
		position: relative;
		height: 110px;
		margin-bottom: 23px;

		img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
		}

		svg {
			position: relative;
			top: -10px;
			max-width: 157px;
			fill: #5B6E91;
			transition: all 1s;
			opacity: 0;

			&.animated {
				top: 0;
				opacity: 1;
			}
		}
	}

	&__txt {
		margin: 0;
		line-height: 1.4;
	}

	&__tip {
		position: absolute;
		display: none;
		min-width: 200px;
		max-width: 100%;
		left: 50%;
		bottom: 10px;
		transform: translateX(-50%);
		background: white;
		box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
		padding: 5px 10px;

		p {
			margin: 0;
		}
	}
}

.svg-clock {
	&__arrows {
		path {
			transform: none;
			transition: all 1.6s;

			&:first-child {
				transform-origin: top left;
			}

			&:last-child {
				transform-origin: bottom left;
			}
		}
	}

	&.animated {
		.svg-clock__arrows path {
			transform: rotate(360deg)
		}
	}
}

.svg-chart {
	margin-top: 18px;

	&__line {
		transition: all 1s;
		transform: scaleY(0);
		transform-origin: bottom;
	}

	&.animated {
		.svg-chart__line {
			transform: scaleY(1);
		}
	}
}

.svg-boxes {
	margin-top: 17px;

	&__box {
		opacity: 0;
		transform: translateY(-100%);

		&:nth-child(1) {
			transition: all .25s;
		}

		&:nth-child(2) {
			transition: all .5s;
		}

		&:nth-child(3) {
			transition: all 1s;
		}

		&:nth-child(4) {
			transition: all 1.25s;
		}

		&:nth-child(5) {
			transition: all 1.5s;
		}
	}

	&.animated {
		.svg-boxes__box {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

.svg-flags {
	margin-top: 5px;

	&__flag {
		opacity: 0;
		transform: translateY(-100%);

		&:nth-child(1) {
			transition: all .25s;
		}

		&:nth-child(2) {
			transition: all .5s;
		}

		&:nth-child(3) {
			transition: all 1s;
		}

		&:nth-child(4) {
			transition: all 1.25s;
		}

		&:nth-child(5) {
			transition: all 1.5s;
		}
	}

	&.animated {
		.svg-flags__flag {
			opacity: 1;
			transform: translateY(0);
		}
	}
}