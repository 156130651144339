.search {
	position: relative;
	width: 230px;
	
	&__field {
		width: 100%;
		height: 38px;
		background-color: rgb(224, 225, 234);
		box-shadow: inset 0px 2px 5px 0px rgba(0, 0, 0, 0.07);
		border: none;
		border-radius: 25px;
		padding-left: 25px;
		color: #474a51;

		&:focus {
			outline: none;
		}
	}

	&__submit {
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		width: 22px;
		height: 22px;
		background: url('../img/icons/search.png') no-repeat center;
		background-size: contain;
		border: none;
	}
}