﻿
@font-face {
	font-family: "MyriadPro";
	src: url("../fonts/MyriadProRegular.eot");
	src: url("../fonts/MyriadProRegular.eot?#iefix")format("embedded-opentype"),
	url("../fonts/MyriadProRegular.woff") format("woff"),
	url("../fonts/MyriadProRegular.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}


@font-face {
    font-family: 'Lora';
    src: url('../fonts/lora-bold-webfont.eot');
    src: url('../fonts/lora-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lora-bold-webfont.woff2') format('woff2'),
         url('../fonts/lora-bold-webfont.woff') format('woff'),
         url('../fonts/lora-bold-webfont.ttf') format('truetype'),
         url('../fonts/lora-bold-webfont.svg#lorabold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Lora';
    src: url('../fonts/lora-italic-webfont.eot');
    src: url('../fonts/lora-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lora-italic-webfont.woff2') format('woff2'),
         url('../fonts/lora-italic-webfont.woff') format('woff'),
         url('../fonts/lora-italic-webfont.ttf') format('truetype'),
         url('../fonts/lora-italic-webfont.svg#loraitalic') format('svg');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'MyriadPro';
    src: url('../fonts/MyriadPro-LightSemiExt.eot?#iefix') format('embedded-opentype'),  
         url('../fonts/MyriadPro-LightSemiExt.otf')  format('opentype'),
         url('../fonts/MyriadPro-LightSemiExt.woff') format('woff'), 
         url('../fonts/MyriadPro-LightSemiExt.ttf')  format('truetype'), 
         url('../fonts/MyriadPro-LightSemiExt.svg#MyriadPro-LightSemiExt') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'MyriadPro';
    src: url('../fonts/MyriadPro-It.eot');
    src: url('../fonts/MyriadPro-It.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MyriadPro-It.woff2') format('woff2'),
        url('../fonts/MyriadPro-It.woff') format('woff'),
        url('../fonts/MyriadPro-It.ttf') format('truetype'),
        url('../fonts/MyriadPro-It.svg#MyriadPro-It') format('svg');
    font-weight: normal;
    font-style: italic;
}
