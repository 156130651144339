﻿@media screen and (max-width:1024px) {	

}

@media (max-width: 1270px) {
	.container {
		padding: 0 20px;
	}

	.header {
		&__cell_rght {
			.btn {
				display: block;
				margin: 25px 0 0 auto;
			}
		}

		&__tel {
			margin: 0;
		}
	}

	.menu {
		&__item {
			padding: 0 12px;
		}
	}

	.news {
		&__item-title {
			font-size: 17px;
		}
	}

	// .section {
	// 	padding: 0 0 20px;
	// }

	.footer {
		&__menu-item {
			margin-right: 15%;
		}

		&__logo {
			width: 200px;

			img {
				max-width: 100%;
				transform: translate3d(0,0,0);
			}
		}
	}

	.item {
		margin-left: 1.33%;
	}

	.desc {
		&__text {
			margin-left: 80px;
			padding-left: 280px;
			font-size: 15px;
		}
	}

	.contacts {
		&__cell_soc {
			padding-left: 0;
		}
	}

	.unit {
		&__wrap {
			width: 840px;
		}
	}
}

@media (max-width: 1000px) {
	.container {
		padding: 0 10px;
	}

	.menu {
		&__item {
			padding: 0 10px;
			margin-bottom: 10px;
		}
	}

	.f-block {
		padding: 20px 10px;

		&__val {
			strong {
				font-size: 40px;
			}
		}

		&__txt {
			font-size: 15px;
		}
	}

	.catalog {
		&__item-title {
			padding: 25px 10px 10px;
			font-size: 20px;
		}
	}

	.news {
		&__item_index {
			.news__item-wrap {
				padding: 10px;
			}
		}

		&__item-title {
			font-size: 15px;
		}
	}

	.interview {
		&__cell {
			display: block;
			width: 100%;
			margin: 0 0 10px;
			
			&:nth-child(1n) {
				padding: 0;
			}
		}
	}

	.footer {
		&__main {
			display: block;
		}

		&__logo {
			margin: 0 auto;
			width: auto;
			max-width: 100%;
		}

		&__cell {
			display: inline-block;
			width: 49%;
			padding-left: 20px;
			// text-align: center;
		}

		&__menu {
			text-align: left;
		}

		&__menu-item {
			margin: 0 0 10px;
		}

		&__address {
			display: block;
			margin: 0 auto 20px;
		}

		&__tel {
			display: block;
			text-align: left;
		}
	}

	.social {
		margin: 0;
		text-align: center;
	}

	.item {
		&:nth-child(1n) {
			width: 32%;
			margin-left: 2%;
		}

		&:nth-child(1),
		&:nth-child(3n+4) {
			margin-left: 0;
		}
	}

	.desc {
		min-height: initial;
		margin: 0;

		&__img {
			display: none;
		}

		&__text {
			margin: 0;
			padding: 20px;
			min-height: initial;
		}
	}

	.contacts {
		&__cell {
			display: block;
			width: 100%;
			margin-bottom: 20px;
		}
	}

	.unit {
		display: block;
		width: 100%;
		font-size: 15px;

		&__cell {
			display: block;
			width: 100%;
		}

		&__wrap {
			display: block;
			width: auto;
			padding: 0 20px;
		}
	}

	.newsitem {
		&__content {
			display: block;
			width: 100%;
			padding: 0;
		}

		&__gallery {
			display: block;
			width: 100%;
		}
	}

	.gallery {
		text-align: center;

		&__photo {
			display: inline-block;
			vertical-align: top;
			margin: 20px 10px;
		}
	}

	.section {
		&__sidebar {
			display: block;
			width: 100%;
			max-width: initial;
			margin: 0 0 20px;
		}

		&__main {
			display: block;
			width: 100%;
		}
	}
}

@media (max-width: 768px) {
	.fhf {
		display: block;
		width: 100%;

		&__cell {
			display:block;
			text-align: center; 
		}

		&__caption {
			font-size: 18px;
		}

		&__title {
			padding: 0;
			font-size: 64px;
		}
	}

	.container {
		padding: 0;
	}

	.search {
		display: none;
	}

	.header {
		padding: 0;
		text-align: center;

		&__navigation {
			display: none;
		}

		&__main {
			display: none;
		}

		&__main_fhf {
			display: block;
			margin: 20px 0 0;
		}
	}

	.slider {
		height: auto;
		padding: 0;

		.flickity-prev-next-button {
			width: 28px;
			height: 28px;
			top: 60px;
			transform: none;
			background: rgba(255,255,255,.3);

			.arrow {
				fill: white;
			}
		}
	}

	.slide {
		padding: 0;
		&__bg {
			height: 140px;
			border-radius: 0;
		}

		&__text {
			position: relative;
			left: 0;
			top: 0;
			transform: none;
			font-size: 18px;
			border-radius: 0;
		}
	}

	.group {
		h1 {
			margin: 20px 0;
			font-size: 28px;
		}
	}

	.medals {
		display: none;
	}

	.section {
		padding: 20px;
	}

	.section_fhf {
		margin: 40px 0;
	}

	.section.about {
		padding: 30px 5px;
	}

	.about {
		&__blocks {
			padding: 0 20px;
		}
	}

	.f-block {
		display: block;
		width: 100%;
		border-top: none;
		border-left: 3px solid #a9b0c8;

		&:first-child {
			border-radius: 8px 8px 0 0;
			border-top: 3px solid #a9b0c8;
			border-bottom: 3px solid #a9b0c8;
		}

		&:last-child {
			border-radius: 0 0 8px 8px;
		}

		&__val {
			strong {
				font-size: 46px;
			}
		}

		&__tip {
			max-width: 200px;
		}
	}

	.catalog {
		&__item:nth-child(1n) {
			display: block;
			width: 100%;
			margin: 0 auto 20px;
			max-width: 400px;
		}
	}

	.news {
		&__item:nth-child(1n) {
			display: block;
			width: 100%;
			margin: 0 0 20px;
		}
	}

	.interview {
		&__img {
			display: none;
		}

		&__body {
			padding: 20px;
			text-align: center;
		}

		&__quest {
			text-align: left;

			.btn {
				display: block;
				margin: 30px auto 0;
			}
		}
	}

	.title {
		font-size: 24px;
	}

	.footer {
		padding: 40px 20px;

		&__bot {
			margin-bottom: 20px;
		}

		&__cell {
			display: block;
			width: 100%;
			padding: 0;
		}
		&__logo {
			display: none;
		}

		&__menu {
			font-size: 15px;
			height: 130px;
		}

		&__address {
			font-size: 15px;
		}

		&__tel {
			font-size: 24px;
		}

		&__copy {
			width: 200px;
		}

		&__develop {
			margin-top: 20px;
			float: none;
		}
	}

	.social {
		margin: 30px 0 0;
		text-align: left;
	}

	.category {
		&__list {
			text-align: center;
		}

		&__item {
			margin: 0 5px 10px;
			font-size: 16px;
		}
	}

	.news_page {
		margin: 30px 0 0;
	}

	.item {

		&:nth-child(1n) {
			display: block;
			width: 100%;
			margin: 0 auto 20px;
			max-width: initial;
		}
	}

	.section {
		&__content_page {
			padding: 20px 0;
		}
	}

	.unit {
		&__wrap {
			display: block;
		}

		&__cell {
			display: block;
			width: auto;

			&:nth-child(1n) {
				text-align: center;
			}
		}
	}

	.contacts {
		&__buttons {
			.btn {
				display: block;
				margin: 0 auto 20px !important;
			}
		}

		&__cell {
			padding: 0;
			background-size: contain;
		}

		&__tel {
			font-size: 24px;
		}

		.title {
			text-align: center;
			

			span {
				display: block;
				width: 100%;

				&::before,&::after {
					display: none;
				}
			}
		}
	}

	.quote {
		font-size: 18px;
		padding: 20px 80px;

		&::after, 
		&::before {
			font-size: 80px;
		}
	}

	.vacancy {
		&__cell {
			display: block;
			width: 100%;
			padding: 0;
		}
	}

	.mobile {
		display: block;
	}

	.menu {
		text-align: left;

		&__item {
			display: block;
			padding: 0;
			margin-bottom: 10px;

			&::before, &::after {
				display: none;
			}
		}
	}

	.modal {
		max-width: 95%;
		padding: 20px 30px;
	}

	.form {
		&__lbl {
			display: block;
			width: 100%;
			margin: 0 0 10px;
		}

		&__line {
			padding: 0;
		}

		&__agree {
			width: 100%;
		}
	}
}

@media (max-width: 500px) {
	.news {
		&__item_index {
			height: auto;

			.news__item-img {
				display: block;
				width: 100%;
				height: 200px;
			}

			.news__item-wrap {
				display: block;
				width: 100%;
				padding-bottom: 20px;
			}

			.news__item-date {
				margin-bottom: 5px;
			}
		}
	}
	
	.gallery {
		margin: 20px 0;

		&__photo {
			display: block;
			width: 100%;
			margin: 0 0 20px;
		}
	}

	.quote {
		padding: 10px;
	}
}