.gallery {
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 0;

	&__photo {
		position: relative;
		display: block;
		margin-bottom: 16px;
		border: 4px solid white;
		border-radius: 8px;
		width: 285px;
		height: 225px;
		max-width: 100%;
		overflow: hidden;
		cursor: pointer;

		img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
		}
	}
}