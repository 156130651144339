.about {
	position: relative;
	padding: 60px 0;
	text-align: center;

	&::before {
		position: absolute;
		top: 0;
		right: -75px;
		width: 506px;
		height: 506px;
		content: '';
		background: url('../img/bg.png') no-repeat center;
	}

	&__text {
		width: 700px;
		max-width: 100%;
		margin: 0 auto;
		text-align: center;
		font-size: 17px;
		line-height: 1.471;
		font-weight: 200;
		color: #363636;

		p {
			margin: 0;
		}
	}

	&__blocks {
		display: table;
		width: 100%;
		margin: 45px 0 23px;
		padding: 0;
		list-style: none;
		font-size: 0;
	}
}