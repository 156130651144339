.vacancy {

	&__cell {
		display: table-cell;
		vertical-align: top;
		width: 50%;
		padding-right: 2%;
	}

	&__list {
		margin: 20px 0 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		position: relative;
		padding-left: 50px;
		margin-bottom: 20px;

		&::before {
			position: absolute;
			left: 15px;
			top: 11px;
			width: 18px;
			height: 2px;
			content: '';
			background: #365e95;
		}

		a {
			font-size: 17px;
			color: #365e95;
			text-decoration: none;
		}
	}

	&__caption {
		display: block;
		margin-bottom: 8px;
		font-size: 20px;
		font-family: @lora;
		color: rgb(39, 39, 39);
		font-weight: bold;
		line-height: 1.3;
	}

	p {
		margin: 0;
	}

	&__contact {
		margin-top: 50px;
		padding: 30px;
		background: white;
		border: 4px solid #dce1f1;
		border-radius: 8px;
	}

	&__contact-list {
		margin: 20px 0 0;
		padding: 0;
		list-style: none;

		li {
			margin-bottom: 10px;
		}
	}

	&__link {
		position: relative;
		padding-left: 32px;
		font-size: 17px;
		color: #365e95;
		text-decoration: none;
	}

	&__link_tel {
		font-size: 20px;
		color: black;
		background: url('../img/icons/tel_g.png') no-repeat left center;
	}

	&__link_mail {
		background: url('../img/icons/mail.png') no-repeat left center;
	}
}

.vacancy_avalaible {
	margin-top: 50px;
	padding-top: 35px;
	border-top: 1px dotted #3a4972;
}