.footer {
	padding: 40px 0;

	&__main {
		display: table;
		width: 100%;
		padding-bottom: 41px;
		border-bottom: 1px solid #aaadaf;
		font-size: 17px;
	}

	&__cell {
		display: table-cell;
		width: 25%;
		vertical-align: top;
	}

	&__menu {
		margin: 0;
		padding: 0;
		list-style: none;
		height: 140px;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}

	&__menu-item {
		margin-bottom: 15px;
		margin-right: 25%;

		a {
			color: white;
			text-decoration: none;
			transition: all .15s;

			&:hover {
				color: @yellow;
			}
		}
	}

	&__address {
		position: relative;
		padding-left: 30px;
		margin: 0 0 43px;
		line-height: 1.471;
		text-align: left;
		display: inline-block;

		&::before {
			position: absolute;
			left: 0;
			top: 3px;
			content: '';
			width: 16px;
			height: 22px;
			background: url('../img/icons/place.png') no-repeat;
		}
	}

	&__tel {
		display: inline-block;
		vertical-align: top;
		font-size: 28px;
		line-height: 0.893;
		text-decoration: none;
		color: white;
		padding-left: 30px;
		background: url('../img/icons/tel.png') no-repeat left center;
		transition: all .15s;

		&:hover {
			color: @yellow;
		}

	}

	&__bot {
		padding: 23px 0;
	}

	&__copy {
		display: inline-block;
		margin: 0;
		font-size: 15px;
		line-height: 1.667;
	}

	&__develop {
		margin: -10px 0 0;
		float: right;

		a {
			display: inline-block;
			margin-left: 8px;

			&:hover svg {
				fill: @yellow;
			}
		}

		svg {
			position: relative;
			top: 5px;
			width: 124px;
			height: 28px;
			fill: white;
			transition: all .15s;
		}
	}
}