.slider {
	padding: 21px 0;

	&__slides {
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

.slide {
	position: relative;
	padding-bottom: 23px;
	max-width: 100%;
	width: 1240px;
	opacity: 0;
	transition: opacity .5s;

	&.is-selected {
		opacity: 1;
	}

	&__bg {
		height: 480px;
		border-radius: 10px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	&__text {
		// font-family: @lora;
		font-style: italic;
		// color: #474a51;
		font-size: 26px;
		// background: white;
		border-radius: 10px;
		padding: 22px 50px 28px;
		max-width: 655px;
		width: 100%;
		margin: 0 auto;
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		line-height: 1.2;

		p {
			margin: 0;
		}

		// &::before, &::after {
		// 	position: absolute;
		// 	top: 50%;
		// 	content: '';
		// 	width: 6px;
		// 	height: 6px;
		// 	background: #474a51;
		// 	transform: translateY(-50%);
		// 	border-radius: 50%;
		// }

		// &::before {
		// 	left: 17px;			
		// }

		// &::after {
		// 	right: 17px;
		// }
	}
}

.slide_noslider {
	margin: 15px 0 40px;
	opacity: 1;
	padding-bottom: 55px;

	.slide__bg {
		height: 450px;
	}

	.slide__text {
		max-width: 825px;
		color: #3a4972;
	}
}